<template>
  <div>
    <div class="ml10 mb10">
      <title-item title="ТОК ТОК ХАРИЛЦАГЧТАЙ ХАРИЛЦАХ АЛБА" />
    </div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column type="index" label="№" width="50px"> </el-table-column>
      <el-table-column prop="questions" label="Хандах асуудал">
      </el-table-column>
      <el-table-column prop="position" label="Албан тушаал"> </el-table-column>
      <el-table-column label="Утас" prop="phone"> </el-table-column>
      <el-table-column label="Мэйл" prop="email"> </el-table-column> </el-table
    ><br />
    <div class="ml10 mb10">
      <title-item title="ТОК ТОК ХАРИЛЦАГЧТАЙ ХОЛБОГДОХ СУВАГ" />
    </div>
    <el-table :data="tableData1" border style="width: 100%">
      <el-table-column type="index" label="№" width="50px"> </el-table-column>
      <el-table-column prop="position" label="Суваг"> </el-table-column>
      <el-table-column prop="phone" label="Холбоо барих дугаар, холбоос">
      </el-table-column>
      <el-table-column label="Цагийн хуваарь" prop="time"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
import TitleItem from "../../../components/items/titleItem.vue";
export default {
  name: "phone",
  components: { TitleItem },
  data() {
    return {
      tableData: [
        {
          questions: "Гэрээ, хүргэлттэй холбоотой",
          position: "Борлуулалтын менежер",
          phone: "95015558",
          email: "salessenior_otd@toktok.mn",
        },
        {
          questions: "Маркетинг, идэвхжүүлэлттэй холбоотой",
          position: "Маркетингийн менежер",
          phone: "94000106",
          email: "salesmanager@toktok.mn",
        },
        {
          questions: "order.toktok.mn болон client.toktok.mn зааварчилгаа",
          position: "Апп-админ",
          phone: "95991613",
          email: "appadmin@toktok.mn",
        },
        {
          questions: "Хүргэлтийн бүс үйл ажиллагаатай холбоотой",
          position: "Үйл ажилгааны албаны ахлах менежер",
          phone: "94097771",
          email: "toktokmanager@toktok.mn",
        },
        {
          questions: "Тооцоо нийлэх, нэхэмжлэх өгөх, мөнгө шилжүүлэх, авах",
          position: "Тооцооны ажилтан",
          phone: "94088112",
          email: "stockkeeper@toktok.mn",
        },
      ],
      tableData1: [
        {
          position: "Харилцагчийн дуудлагын төв",
          phone: "7505-7766",
          time: "Өдөр бүр: 10:00-02:00",
        },
        {
          position: "Харилцагчийн нэгдсэн групп",
          phone: "https://www.facebook.com/groups/toktokclient ",
          time: "24 цаг",
        },
        {
          position: "Харилцагчийн удирдлагын систем",
          phone: "https://www.client.toktok.mn",
          time: "24 цаг",
        },
      ],
    };
  },
};
</script>
